import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-subscribers-view',
  templateUrl: './subscribers-view.component.html',
  styleUrls: ['./subscribers-view.component.css']
})
export class SubscribersViewComponent implements OnInit {

  contactUsId: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.contactUsId = res.id;
    })
    this.viewBlog()
  }

  // edit faq english
  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.getApi("admin/subscribeView?subscribeId=" + this.contactUsId, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.editData = res.result;
        this.commonService.hideSpinner()

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }

}
