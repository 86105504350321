import { ToastrModule } from 'ng6-toastr-notifications';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any
@Component({
  selector: 'app-wallet-management',
  templateUrl: './wallet-management.component.html',
  styleUrls: ['./wallet-management.component.css']
})
export class WalletManagementComponent implements OnInit {
  selected: string = "btc";
  coinlist: any = [];
  coinListArr: any = [];
  transferForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 10;
  currentPage: number = 1;
  totalItems: any
  flip: string = "List";
  minAge: Date

  today: any = new Date().toISOString().slice(0, 16)

  constructor(
    private router: Router,
    public service: ServiceService
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();
    this.coins()
    this.getAllTransactionHistory();
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())
  }

  fromDateVali: any = ''
  fromdate() {
    this.fromDateVali = new Date(this.transferForm.value.fromDate)
    this.fromDateVali = this.fromDateVali.getTime()
    console.log(this.fromDateVali);

  }

  copyToClipboard(item) {

    this.service.toasterSucc('copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }


  pagination(page) {
    this.currentPage = page
    this.getAllTransactionHistory();
  }

  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'currency': new FormControl(''),
      'type': new FormControl(''),
      'searchTextTxnHash': new FormControl(''),
      'amount': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl('')
    })
  }

  getAllTransactionHistory() {
    let data = {
      page: this.currentPage,
      limit: this.itemsPerPage,
      fromDate: this.transferForm.value.fromDate,
      toDate: this.transferForm.value.toDate,
      search: this.transferForm.value.amount,
      coinName: this.transferForm.value.coinNameSe
    }
    this.service.postApiFunc(`admin/transactionList`, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.service.hideSpinner();
        this.loopData = res.result.docs;
        this.lengthTotal = res.result.total
        // this.service.toasterSucc(res.responseMessage)
      } else {
        this.loopData = []
        this.service.toasterErr(res.responseMessage);
        this.service.hideSpinner();
      }
    }, err => {
      this.loopData = []
      this.service.hideSpinner();
      this.service.toasterErr(err.error.responseMessage);
    })

  }
  getAllTransactionHistory1() {
    let data = {
      page: this.currentPage,
      limit: this.itemsPerPage,
      fromDate: this.transferForm.value.fromDate,
      toDate: this.transferForm.value.toDate,
      search: this.transferForm.value.amount,
      coinName: this.transferForm.value.coinNameSe,
      transactionType : 'CRYPTO_PAY'
    }
    this.service.postApiFunc(`admin/transactionList`, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.service.hideSpinner();
        this.loopData = res.result.docs;
        this.lengthTotal = res.result.total
        // this.service.toasterSucc(res.responseMessage)
      } else {
        this.loopData = []
        this.service.toasterErr(res.responseMessage);
        this.service.hideSpinner();
      }
    }, err => {
      this.loopData = []
      this.service.hideSpinner();
      this.service.toasterErr(err.error.responseMessage);
    })

  }


  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.type ||
      this.transferForm.value.searchTextTxnHash ||
      this.transferForm.value.amount ||
      this.transferForm.value.coinNameSe ||
      this.transferForm.value.status

    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        type: "",
        searchTextTxnHash: "",
        amount: "",
        coinNameSe: "",
        status: "",


      });
      this.getAllTransactionHistory();
    }
  }

  // sidemenu() {
  //   $(".btn-toggle,.close_panel").click(function() {
  //     $("body").toggleClass("toggle-wrapper");
  // });
  // }

  // to check tab


  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.loopData = this.loopData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.loopData = this.loopData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  shortAddress(address) {
    const shortedValue = `${address.slice(0, 6)}....${address.slice(address.length - 4)}`
    return shortedValue
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.loopData.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Username": element.userId.firstName ? element.userId.firstName + element.userId.lastName : '',
        "Email": element.userId.email ? element.userId.email : '',

        "Coin": element.coinName ? element.coinName : 'N/A',
        "Transaction Type": element.transactionType ? element.transactionType : 'N/A',
        "From Address": element.fromAddress ? element.fromAddress : 'N/A',
        "To Address": element.toAddress ? element.toAddress : 'N/A',
        "Amount": element.quantity ? element.quantity : 'N/A',
        "Created Date & Time": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
      })
      // let fhkds= JSON.stringify(dataArr)
    })

    this.service.exportAsExcelFile(dataArr, 'Wallet Transaction list');
  }

  // User or Company get status Function

  uesrType: any = "USER"
  tab: any = "user"
  tab1: any = ''
  select(status, status1) {
    this.tab = status;
    this.tab1 = status1
    this.uesrType = this.tab.toUpperCase()
    if (this.tab == 'company') {
      this.getAllTransactionHistory1();
    } else {
      this.getAllTransactionHistory()
    }
  }
  // coin Balance apis
  coin: any = []
  coinNames: any
  coins() {
      let url = `admin/coinList`
      this.service.showSpinner();
      this.service.getApi(url, 0).subscribe(res => {
          if (res.responseCode == 200) {
              this.coin = res.result
              this.service.hideSpinner();
          } else {
              this.service.hideSpinner();
              this.service.toasterErr(res.responseMessage);
          }
      }, (err) => {
          this.service.hideSpinner();
          this.service.toasterErr(err.error.responseMessage)
      })
  }
}
