import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';



declare var $: any
@Component({
  selector: 'app-list-blog',
  templateUrl: './list-blog.component.html',
  styleUrls: ['./list-blog.component.css']
})
export class ListBlogComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  bannerId: any;
  careerStatus: string;

  contentId: any;

  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getEmbellishmentList();
  }
  openModal(careerStatus, contentId) {
    this.contentId = contentId;
    this.careerStatus = careerStatus;
    if (careerStatus == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }

  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  // -------------------- get category list --------------------- //
  getEmbellishmentList() {
    // let apiReqUrl: any = `static/admin/static-content/get-Blog-list?page=0&pageSize=${this.itemPerPage}`
    const data = {
      page: this.currentPage,
      limit: this.itemPerPage
    }
    let apiReqUrl: any = `blog/listBlog`
    this.commonService.showSpinner();
    this.commonService.postApiFunc(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.bodyListArray = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage)
      }
    }, (err) => {
      console.log(err);
      this.commonService.hideSpinner();
      if (err.responseCode == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
    })
  }
  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getEmbellishmentList()
  }
  addBody() {
    this.router.navigate(['/add-blog'])
  }
  // view category
  viewBody(contentId) {
    this.router.navigate(['/view-blog'], { queryParams: { contentId: contentId } })
  }
  // edit category
  editBody(contentId) {
    this.router.navigate(['/edit-blog'], { queryParams: { contentId: contentId } })
  }

  deleteCategoryModal(contentId) {
    $('#deleteCategory').modal('show')
    this.contentId = contentId
  }
  deleteCategory() {
    let apiReqUrl: any = "blog/deleteBlog?_id=" + this.contentId
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.responseCode == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage)
      }
    })
  }
}
