import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-my-profile-contact-update',
  templateUrl: './my-profile-contact-update.component.html',
  styleUrls: ['./my-profile-contact-update.component.css']
})
export class MyProfileContactUpdateComponent implements OnInit {

  userDetail: any = {};
  editForm: FormGroup;
  editImage: any;
  imageUrl: any

  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.myProfile();
    this.contactValidtion();
  }

  reg = '(https?://)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*/?';

  contactValidtion() {
    this.editForm = new FormGroup({
      'MobileNumber': new FormControl(''),
      'Twitter': new FormControl('', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      'Website': new FormControl('', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      'Instagram': new FormControl('', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      'Facebook': new FormControl('', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
    })
  }

  myProfile() {
    var url = 'admin/adminProfile';
    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe(res => {
      // this.service.hideSpinner();  
      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        this.userDetail = res['result']['contactUs'];
        // this.editImage = this.userDetail.imageUrl;
        this.editForm.patchValue({
          'MobileNumber': this.userDetail.mobileNumber,
          'Twitter': this.userDetail.twitter,
          'Facebook': this.userDetail.facebook,
          'Website': this.userDetail.website,
          'Instagram': this.userDetail.instagram,
        })
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
      // this.service.hideSpinner();
    })
  }

  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }

  async uploadImg1($event) {
    this.imageUrl = await this.service.getBase64($event.target.files[0]);
  }

  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == '200') {
        this.editImage = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  updateProfile() {
    // if (this.editForm.invalid) {
    //   return
    // }
    var apiReq = {
      'mobileNumber': this.editForm.value.MobileNumber,
      'twitter': this.editForm.value.Twitter,
      'facebook': this.editForm.value.Facebook,
      'website': this.editForm.value.Website,
      'instagram': this.editForm.value.Instagram,
    }
    this.service.showSpinner();
    this.service.putApi('admin/updateContactUs', apiReq, 1).subscribe(res => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.service.toasterSucc('Profile Updated Successfully');
        this.router.navigate(['/my-profile'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    }
  }

}
