import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-contactus-management',
  templateUrl: './contactus-management.component.html',
  styleUrls: ['./contactus-management.component.css']
})
export class ContactusManagementComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  contactUsId: any;

  // hospitalIds: any = [];
  // // checked: any
  // isCheckedAll: any = false;
  // checked: any
  // toDate: string | number | Date;
  // newDate: string | number | Date;
  // oldDate: string | number | Date;
  // fromDate: string | number | Date;
  // today: string
  today: any = new Date().toISOString().slice(0, 16)

  userStatus: any = 'ACTIVE';

  //    tooltips = require('tooltip')

  // tooltip()

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    // this.searchForm.get('fromDate').valueChanges.subscribe((x)=>{
    //   console.log('x',x);
    //   this.fromDate = x
    // })
    this.getEmbellishmentList();
    // this.commonService.BlockFuture()
    // this.today = new Date().toISOString()
    console.log(this.today)
  }

  // Search by Date
  //    searchByDate(){

  //  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      // search: new FormControl(''),
      // status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }



  selectStatus() {
    this.currentPage = 1
  }
  // -------------------- get category list --------------------- //
  getEmbellishmentList() {
    let apiReqUrl: any = `contactUs/contactUs?page=${this.currentPage - 1}&limit=${this.itemPerPage}`
    // let apiReqUrl = `static/get-contact-us-details`fromDate=13121211&&toDate=13121211

    this.commonService.showSpinner();

    // remove empty keys from request body
    // apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.bodyListArray = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        this.commonService.toasterSucc(res.responseMessage)
        this.commonService.hideSpinner();
        // this.commonService.toasterSucc(res.responseMessage);
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage)
      }
    }, (err) => {
      console.log(err);
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.error.responseMessage)
      if (err.responseCode == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }

  pagination(page) {
    // console.log(event)
    this.currentPage = page;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/add-announcement'])
  }
  // view category
  viewBody(contactUsId) {
    // alert(contactUsId)
    this.router.navigate(['/contactus-view'], { queryParams: { contactUsId: contactUsId } })
  }

  // edit category
  editBody(contactUsId) {
    this.router.navigate(['/edit-announcement'], { queryParams: { contactUsId: contactUsId } })
  }
  // editCategory(contactUsId) {
  //   this.router.navigate(['/embellishment/edit-embellishment'])
  // }
  // blockUnblockUserModal(){

  // }
  // ------------------------------- delete hospital ----------------------------- //
  deleteCategoryModal(contactUsId) {
    $('#deleteCategory').modal('show')
    this.contactUsId = contactUsId
  }
  deleteCategory() {
    // let data = {
    //   hospitalId: this.hospitalId
    // }
    // console.log(data)
    let apiReqUrl: any = "static/delete-announcement?contactUsId=" + this.contactUsId
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      // console.log("delete category user response ==>", res)
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }


  ekFunctionZindgiKa() {
    let fromDate = Date.parse(this.searchForm.value.fromDate);
    let toDate = Date.parse(this.searchForm.value.toDate);

    let url = `static/get-contact-us-details?page=${this.currentPage - 1}&pageSize=${this.itemPerPage}${this.searchForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.searchForm.value.toDate ? "&toDate=" + toDate : ""}`
    //  let zindgi  =  `wallet/admin/transaction-history/get-all-transaction-history?amount=${this.transferForm.value.amount}&coinName=${this.transferForm.value.coinNameSe}&fromDate=${Math.round(new Date(this.transferForm.value.fromDate).getTime())}&page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&status=${this.transferForm.value.status}&toDate=${Math.round(new Date(this.transferForm.value.toDate).getTime())}&txnHash=${this.transferForm.value.searchTextTxnHash}&txnType=${this.transferForm.value.type}`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.bodyListArray = res.data.List ? res.data.List : '';
        }
        this.totalItems = res.data.Count

        this.commonService.hideSpinner();
      },
      (err: any) => {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(err.error.message);
      }
    );
  }

  reset() {
    if (
      this.searchForm.value.fromDate ||
      this.searchForm.value.toDate


    ) {
      this.searchForm.reset({
        fromDate: "",
        toDate: "",



      });
      this.getEmbellishmentList();
    }
  }
}
