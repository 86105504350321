import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $
@Component({
  selector: 'user-details-kyc',
  templateUrl: './user-details-kyc.component.html',
  styleUrls: ['./user-details-kyc.component.css']
})
export class UserDetailsKycComponent implements OnInit {

  modalForm: FormGroup
  @Input() userId: any;
  viewData: any;
  documentId: any;
  kycId: any;
  kycStatus: any;
  ima: any
  docData: any
  @Input() tab: any
  arrowStatus: boolean = false;

  constructor(public router: Router, public service: ServiceService, public active: ActivatedRoute) {
   
  }

  ngOnInit(): void {
    // this.viewKyc();
    this.modalForm = new FormGroup({
      'reason': new FormControl('', Validators.required)
    })
    this.showUserDetail()
  }

  openArrow() {
    // if (this.arrowStatus == false) {
    //   this.arrowStatus = true
    //   console.log(this.arrowStatus);
    // } else {
    //   this.arrowStatus = false;
    //   console.log(this.arrowStatus);
    // }
    this.arrowStatus = !this.arrowStatus


  }

  driving: any
  national: any
  passport: any
  userDetail: any
  companyDetail: any
  showUserDetail() {
    this.service.showSpinner()
    var url = "admin/viewKyc?kycId=" + this.userId
    this.service.getApi(url, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.userDetail = res.result;
        this.driving = res.result.driving;
        this.national = res.result.national;
        this.passport = res.result.passport;
        this.companyDetail = res.result.companyHolder
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  companydetail: any
  // Api of view kyc
  viewKyc() {
    var url = "account/admin/kyc-management/get-kyc-details?userId=" + this.userId;
    this.service.get(url).subscribe((res: any) => {
      // console.log('view', res);
      if (res.status == 200) {
        this.viewData = res.data.document[0];
        // this.docData = res.data.kyc.document[0]
        this.kycStatus = res.data.kycStatus
        this.companydetail = res.data
        this.documentId = res.data.document[0].documentId;
        this.kycId = res.data.kycId;
      }
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access.')
      }
      else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

  approved() {
    if (this.userDetail.approveStatus == 'ACCEPTED') {
      this.service.toasterErr('Already kyc approved.');
    }
    else {
      $('#approveModal').modal('show')
    }
  }

  rejected() {
    if (this.userDetail.approveStatus == 'REJECTED') {
      this.service.toasterErr('Already kyc rejected.');
    }
    else {
      $('#rejectModal').modal('show')
    }
  }

  approveKyc() {
    this.service.showSpinner();
    let data = {
      // "documentId": this.documentId,
      "kycId": this.userId,
      "approveReject": "APPROVE"
    }
    this.service.putApi('admin/approveRejectKyc', data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.service.hideSpinner();
        // this.service.toasterSucc(res.message)
        this.service.toasterSucc('KYC approved successfully.')
        $('#approveModal').modal('hide');
        this.router.navigate(['/user-management'])
      }
      else {
        this.service.hideSpinner();
        this.service.toasterErr(res.responseMessage)
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  rejectKyc() {
    this.service.showSpinner();
    let data = {
      "kycId": this.userId,
      "approveReject": "REJECT",
      "reason": this.modalForm.value.reason,
    }
    this.service.putApi('admin/approveRejectKyc', data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc('KYC rejected successfully.')
        $('#rejectModal').modal('hide')
        this.router.navigate(['/user-management'])
      }
      else {
        this.service.toasterErr(res.responseMessage)
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  image() {
    $('#imgg').modal('show')
  }

  backimage() {
    $('#backimgg').modal('show')
  }
}
