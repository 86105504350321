import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: FormGroup;
  resend: boolean = false

  constructor(
    private router: Router,
    public service: ServiceService
  ) { }

  ngOnInit() {
    this.forgotPassword = new FormGroup({

      'email': new FormControl('', Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)),
    })
  }


  onForgot() {
    var email = this.forgotPassword.value.email;
    //  var url = "account/forget-password?email="+email+'&webUrl='+this.service.websiteURL+'reset-password';
    let url = `admin/forgotPassword`
    let data = {
      email: this.forgotPassword.value.email
    }
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 0).subscribe(res => {

      if (res['responseCode'] == 200) {
        localStorage.setItem('resetPassEmail', email);
        this.router.navigate(['/otp'])
        this.service.toasterSucc('Successfully sent email to your registered email id')
      }
      this.resend = true

      this.service.hideSpinner();
    }, err => {

      this.service.hideSpinner();

      if (err['status'] == '401') {
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Email address is not registered');
      }
    });
  }

  // onResend() {
  //   if (this.forgotPassword.value.email) {
  //     this.onForgot();
  //   }
  //   else {
  //     this.service.toasterErr('Registered email address required so that we can send you reset instruction')
  //   }

  // }


  onResend() {
    var email = this.forgotPassword.value.email;
    //  var url = "account/forget-password?email="+email+'&webUrl='+this.service.websiteURL+'reset-password';
    let url = `admin/resendOTP`
    let data = {
      email: this.forgotPassword.value.email
    }
    this.service.showSpinner();
    this.service.putApi(url, data, 0).subscribe(res => {

      if (res['responseCode'] == 200) {
        this.router.navigate(['/otp'])

        this.service.toasterSucc('Successfully sent email to your registered email id')
      }
      this.resend = true

      this.service.hideSpinner();
    }, err => {

      this.service.hideSpinner();

      if (err['status'] == '401') {
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Email address is not registered');
      }
    });
  }

}


