import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

declare const $: any


@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.css']
})
export class SubscribersComponent implements OnInit {
  [x: string]: any;

  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  staffForm: FormGroup;
  staffList: any;
  modalForm: FormGroup

  constructor(private router: Router, public service: ServiceService) {
    this.staffForm = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      search: new FormControl('')
    })

  }

  ngOnInit(): void {
    this.getStaffList()
    // this.search()
    this.modalForm = new FormGroup({
      'reason': new FormControl('', Validators.required)
    })
  }
  email: any
  mailSend(mail) {
    this.email = mail
    $('#mailModal').modal('show')
  }

  getStaffList() {

    let data = {
      page: this.currentPage,
      limit: this.itemPerPage,
      fromDate: this.staffForm.value.fromDate ? this.staffForm.value.fromDate : "",
      toDate: this.staffForm.value.toDate ? this.staffForm.value.toDate : "",
      search: this.staffForm.value.search ? this.staffForm.value.search : "",
    }

    var url = `admin/subscribeList`;
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 1).subscribe(res => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.staffList = res.result.docs;
        this.staffLength = res.result.total

      }
    }, err => {
      this.service.hideSpinner();
    })

  }
  sendMail() {
    let data = {
      email: this.email,
      message: this.modalForm.value.reason
    }
    var url = `admin/replyToSubscriber`;
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 1).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        $('#mailModal').modal('hide')
        this.service.toasterSucc(res.responseMessage)
      }
    }, err => {
      this.service.toasterErr(err.error.responseMessage)
      this.service.hideSpinner();
    })

  }

  // exportAsXLSX1() {
  //   let dataArr = [];
  //   this.userList.forEach((element, ind) => {

  //     dataArr.push({
  //       "S no": ind + 1,
  //       "Name": element.firstName + '' + element.lastName ? element.firstName : '',
  //       "Email": element.email ? element.email : '',
  //       "Mobile Number": element.mobileNumber ? element.mobileNumber : 'N/A',
  //       "Company Name": element.companyName ? element.companyName : 'N/A',
  //       "GST Number": element.gstNumber ? element.gstNumber : 'N/A',
  //       "TIN Number": element.tinNumber ? element.tinNumber : 'N/A',
  //       "Country": element.country ? element.country : 'N/A',
  //       "Status": element.status ? element.status : 'N/A',
  //       "Registration Date & Time ": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
  //     })
  //   })

  //   this.service.exportAsExcelFile(dataArr, 'User list');
  // }

  exportAsXLSX1() {

    let data = {
      page: this.currentPage,
      limit: this.itemPerPage,
      fromDate: this.staffForm.value.fromDate ? this.staffForm.value.fromDate : "",
      toDate: this.staffForm.value.toDate ? this.staffForm.value.toDate : "",
      search: this.staffForm.value.search ? this.staffForm.value.search : "",
    }

    var url = `admin/subscribeList`;
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 1).subscribe(res => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        let dataArr = [];
        res.result.docs.forEach((element, ind) => {

          dataArr.push({
            "S no": ind + 1,
            "Email": element.email ? element.email : '',
            "Date & Time": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
          })
          // let fhkds= JSON.stringify(dataArr)
        })

        this.service.exportAsExcelFile(dataArr, 'Subscription List');

      }
    }, err => {
      this.service.hideSpinner();
    })

  }


}
