import { Component } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { ServiceService } from './service.service';
import { BnNgIdleService } from 'bn-ng-idle';
declare var $: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bittradeAdminPanel';
  currUrl: any;
  view: boolean = false;
  constructor(public service: ServiceService, public routes: Router, private bnIdle: BnNgIdleService) {

    this.bnIdle.startWatching(10800).subscribe((res) => {
      if (res) {
        this.service.onLogout()
      }
    });


    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
      }
      if (!(this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl == `reset-password` || this.currUrl == `otp` || this.currUrl == ``)) {
        this.view = false
      } else {
        this.view = !this.view
      }
    })
  }

  ngOnInit() {
    // this.checkSession();

    //   $(".btn-toggle,.close_panel").click(function() {
    //     $("body").toggleClass("toggle-wrapper");
    // });
    // this.service.initSocketSupportChat() //
  }

  /** Function to get diffrence between timestamp */
  // diff_minutes(dt2, dt1) {
  //   var diff = (dt2 - dt1) / 1000;
  //   diff /= 60;
  //   return Math.abs(Math.round(diff));
  // }
  // /** Function for check session */
  // checkSession() {
  //   if (localStorage.getItem('token') != null || localStorage.getItem('session') != null) {
  //     let minDiff = this.diff_minutes(parseInt(localStorage.getItem('session')), new Date().getTime());
  //     localStorage.setItem('session', new Date().getTime().toString());
  //     if (minDiff >= 10) {
  //       this.onLogout();
  //     }
  //   } else {
  //     localStorage.setItem('session', new Date().getTime().toString());
  //   }
  // }

  onLogout() {
    localStorage.removeItem('Auth');
    localStorage.removeItem('permission');
    localStorage.removeItem('usertype');
    localStorage.removeItem('adminEmail');
    localStorage.removeItem('userId');
    this.routes.navigate(['/login']);
  }



}
