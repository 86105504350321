import { ServiceService } from './../../service.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-manage-fee',
  templateUrl: './manage-fee.component.html',
  styleUrls: ['./manage-fee.component.css']
})
export class ManageFeeComponent implements OnInit {
  currTab: any = ""
  priceForm: FormGroup;

  constructor(public service: ServiceService) { }
  tradingDetail: any = []
  ngOnInit(): void {
    this.priceForm = new FormGroup({
      price: new FormControl("", [Validators.required, Validators.pattern("")]),
    });
    this.selectTab('MIN_WITHDRAWAL');
    this.totalReferralAndTotalCount();
    // this.tradingFeeList()
  }
  selectTab(tab) {
    this.currTab = tab
  }

  counts: any
  totalReferralAndTotalCount() {
    let url = `admin/referralCountReferralAmount`
    this.service.getApi(url, 1).subscribe(res => {
      if (res.responseCode == 200) {
        this.counts = res.result
      } else {
        this.service.toasterErr(res.responseMessage)
      }
    }, err => {
      this.service.toasterErr(err.error.responseMessage)
    })
  }
  coinList:any= []
  cointAmount:any
//   tradingFeeList(){
//     this.service.get(`admin/coinList`).subscribe((res:any)=>{
//     //  this.coinList= res.result
//      let coinArray = ['VDBC_STELLAR']
//      let myArray = res.result.filter((ele)=>{
//       console.log(ele);
      
//       return coinArray.includes(ele.coinName)
//      })
//      this.coinList=myArray
// console.log(this.coinList);

//      myArray.forEach((element,index) => {
//       this.cointAmount[index] = false 
//     });
//       console.log(this.coinList)
//     })
//   }

}
