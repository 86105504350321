import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';


@Component({
  selector: 'app-usd-price',
  templateUrl: './usd-price.component.html',
  styleUrls: ['./usd-price.component.css']
})
export class UsdPriceComponent implements OnInit {

  coinList: any = [];
  pageNumber = 1;
  cointAmount: any = []
  withdrawAmount: any = []
  constructor(public service: ServiceService) { }

  ngOnInit(): void {
    this.tradingFeeList()
  }

  tradingFeeList() {
    this.service.get(`admin/coinList`).subscribe((res: any) => {
      //  this.coinList=res.data
      let coinArray = ['VDBC_STELLAR']
      let myArray = res.result.filter((ele) => {
        return coinArray.includes(ele.coinName)
      })
      this.coinList = myArray

      myArray.forEach((element, index) => {
        this.cointAmount[index] = false
      });
      console.log(this.coinList)
    })
  }
  enableInputField(i) {
    this.cointAmount.forEach((ele, index) => {
      if (index == i) {
        this.cointAmount[index] = true
      }
      else {
        this.cointAmount[index] = false
      }
    })
  }

  updateCoinAmount(coinName, coinAmount) {
    if (!String(coinAmount).length) {


      this.service.toasterErr(`Please enter usd price of ${coinName}`);

      return

    }
    let data = {
      coinId: coinName,
      price: String(coinAmount)
    }
    this.service.showSpinner()
    this.service.putApi(`admin/editCoin`, data, 1).subscribe((res: any) => {
      if (res.status = 200) {
        // this.service.toasterSucc(res.message);
        this.tradingFeeList()
        this.service.hideSpinner()
      }
      else {
        this.service.hideSpinner()
      }
    })
  }

}
