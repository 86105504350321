import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';


declare var $: any;
@Component({
  selector: 'app-staking-list',
  templateUrl: './staking-list.component.html',
  styleUrls: ['./staking-list.component.css']
})
export class StakingListComponent implements OnInit {

  userForm: FormGroup;
  listing: any = [];
  id: number;
  deleted: any;
  totalRecords: any;
  pageNumber: number = 1;
  itemsPerPage: number = 10;
  userid: any;
  userStatus: any;
  isAssending: boolean = true;
  // today = new Date().toISOString().split("T")[0];
  today: any = new Date().toISOString().slice(0, 16)
  stakingList: any


  constructor(
    private router: Router,
    public service: ServiceService,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.userForm = new FormGroup({
      startdate: new FormControl(""),
      enddate: new FormControl(""),
      searchText: new FormControl(""),
      country: new FormControl(""),
      userStatus: new FormControl(""),
    });
    // this.getlist();
    // this.countryList();
    this.getStakingList();
  }


  getStakingList() {
    let url = `admin/stakeInterestList`
    this.service.hideSpinner();
    this.service.getApi(url, 0).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.stakingList = res['result']
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['responseMessage'])
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.responseMessage)
    })
  }



  products: any = [];
  countryList() {
    this.httpClient.get("assets/country.json").subscribe((data) => {
      // console.log(data);
      this.products = data;
      // console.log(this.products)
    });
  }
  isSearched: boolean = false;
  getlist() {
    let startdate = Date.parse(this.userForm.value.startdate);
    // alert(new Date(this.userForm.value.startdate).getHours() )
    let enddate = Date.parse(this.userForm.value.enddate);
    // let startdate =   this.userForm.value.startdate ? new Date(this.userForm.value.startdate).toISOString() : '';
    // let enddate = this.userForm.value.enddate ? new Date(this.userForm.value.enddate).toISOString() : '';
    var search = this.userForm.value.searchText;
    let countryDa = this.userForm.value.country;
    let url = `account/admin/user-management/filter-user-details?page=${this.pageNumber - 1
      }
    ${this.userForm.value.searchText
        ? "&search=" + search
        : ""
      } 
    ${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}
    ${this.userForm.value.enddate ? "&toDate=" + enddate : ""}
    ${this.userForm.value.country ? "&country=" + countryDa : ""}
    ${this.userForm.value.userStatus
        ? "&status=" + this.userForm.value.userStatus
        : ""
      }
    `;
    // var url = "account/admin/user-management/filter-user-details";
    // var url =
    // "account/admin/user-management/filter-user-details?page=" +
    // (this.pageNumber - 1) +
    // "&pageSize=10";
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.listing = res.data.list;
        }
        this.totalRecords = res.data.totalCount;
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  sortAgent(key) {
    if (this.isAssending) {
      this.listing = this.listing.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.listing = this.listing.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if (this.isSearched) {


      this.search();
    } else {
      // this.getlist();
    }
  }
  search() {
    let startdate = Date.parse(this.userForm.value.startdate);
    let enddate = Date.parse(this.userForm.value.enddate);
    // let startdate =   this.userForm.value.startdate ? new Date(this.userForm.value.startdate).toISOString() : '';
    // let enddate = this.userForm.value.enddate ? new Date(this.userForm.value.enddate).toISOString() : '';
    var search = this.userForm.value.searchText;
    let countryDa = this.userForm.value.country;

    let url = `account/admin/user-management/filter-user-details?page=${this.pageNumber - 1
      }&pageSize=10
    ${this.userForm.value.searchText
        ? "&search=" + search
        : ""
      } 
    ${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}
    ${this.userForm.value.enddate ? "&toDate=" + enddate : ""}
    ${this.userForm.value.country ? "&country=" + countryDa : ""}
    ${this.userForm.value.userStatus
        ? "&status=" + this.userForm.value.userStatus
        : ""
      }
    `;

    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        this.listing = res.data.list;
        console.log("kfg", this.listing);
        // this.totalRecords = res.data.totalCount;
        this.totalRecords = res.data.totalCount;
      },
      (err) => {
        this.service.hideSpinner();
        // this.listing = [];

        // this.totalRecords = err.data.totalCount;
      }
    );
  }

  // reset
  // reset() {
  //   if (
  //     (this.userForm.value.startdate && this.userForm.value.enddate) ||
  //    ( this.userForm.value.searchText  ) || this.userForm.value.country
  //   ) {
  //     this.userForm.reset();
  //     this.getlist();
  //   }
  // }

  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.country ||
      this.userForm.value.userStatus
    ) {
      this.userForm.reset({
        startdate: "",
        userStatus: "",
        enddate: "",
        searchText: "",
        country: "",
      });
      // this.getlist();
    }
  }

  //========modal=======//
  delete(id: number) {
    this.id = id;
    $("#deleteModal").modal("show");
  }
  deleteUser() {
    this.service
      .get(`account/admin/user-management/delete-user-detail?userId=${this.id}`)
      .subscribe(
        (res: any) => {
          this.deleted = res;
          if ((this.deleted.ststus = 200)) {
            $("#deleteModal").modal("hide");
            this.service.toasterSucc(this.deleted.message);
            this.getlist();
          }
        },
        (err) => {
          /* this.service.hideSpinner();  */ if (err["status"] == "401") {
            this.service.onLogout();
            this.service.toasterErr("Unauthorized Access");
          } else {
            this.service.toasterErr("Something Went Wrong");
          }
        }
      );
  }

  block(status, id) {
    this.userid = id;
    this.userStatus = status;
    $("#block").modal("show");
  }
  blockUser() {
    /* this.service.showSpinner(); */
    let data = {
      interestId: this.userid
    }
    var url = "admin/activeBlockStakeInterest"
    this.service.showSpinner();
    this.service.putApi(url, data, 1).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.responseCode == 200) {
          this.service.toasterSucc(res.responseMessage);
          $("#block").modal("hide");
          this.getStakingList();
        }
      },
      (err) => {
        /*  this.service.hideSpinner();   */
        if (err["responseCode"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // user details navigation
  userDetails(id) {
    this.router.navigate(["/user-details"], { queryParams: { id: id } });
  }

  //export User
  async exportAsXLSX() {
    let temp = [];
    var url =
      "account/admin/user-management/filter-user-details?page=" +
      (this.pageNumber - 1) +
      "&pageSize=" +
      this.totalRecords;
    this.service.showSpinner();
    try {
      let res: any = await this.service.get(url).toPromise();

      this.service.hideSpinner();
      if (res.status == 200) {
        temp = res.data.list;
      }
    } catch (error) {
      this.service.hideSpinner();
      this.service.toasterErr(error.error.message);
    }

    let dataArr = [];
    temp.forEach((element, ind) => {
      dataArr.push({
        "S no": ind + 1,
        // "User ID": element.userId ? element.userId : '',
        "User Name":
          element.firstName + "" + element.lastName
            ? element.firstName +
            " " +
            (element.lastName ? element.lastName : "")
            : "",
        Email: element.email ? element.email : "N/A",
        Phone: element.phoneNo ? element.phoneNo : "N/A",
        Status: element.userStatus,
        Date: element.createTime ? element.createTime.slice(0, 10) : "N/A",
      });
    });

    this.service.exportAsExcelFile(dataArr, "Admin User list");
    console.log(dataArr);
  }

}
