import { element } from 'protractor';
import { Component, Input, OnInit } from '@angular/core';

import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-min-withdrawl-amount',
  templateUrl: './min-withdrawl-amount.component.html',
  styleUrls: ['./min-withdrawl-amount.component.css']
})
export class MinWithdrawlAmountComponent implements OnInit {
  coinList: any = [];
  pageNumber = 1;
  cointAmount: any = []
  withdrawAmount: any = []

  @Input() currTab: any

  constructor(public service: ServiceService) { }

  ngOnInit(): void {
    console.log(this.currTab);
    this.withdrawlFeeList()
  }

  // withdrawlFeeList(){
  //   this.service.get(`wallet/coin/get-coin-list`).subscribe((res:any)=>{
  //    this.coinList=res.data
  //    res.data.forEach((element,index) => {
  //       this.cointAmount[index] = false 
  //     });
  //     console.log(this.coinList)
  //   })
  // }
  filteredData: any;
  withdrawlFeeList() {
    this.service.getApi(`admin/feeList?feeType=${this.currTab}`, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.coinList = res.result
        res.result.forEach((element, index) => {
          this.cointAmount[index] = false
        });
        this.filteredData = this.coinList.filter(element => {
          return element.coinType
        })
        console.log(this.filteredData);

        console.log(this.coinList)
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err.error.responseMessage)
    });
  }
  enableInputField(i) {
    this.cointAmount.forEach((ele, index) => {
      if (index == i) {
        this.cointAmount[index] = true
      }
      else {
        this.cointAmount[index] = false
      }
    })
  }

  updateCoinAmount(coinName, coinAmount) {
    console.log(coinAmount)

    if (coinAmount == null || !String(coinAmount).length) {


      this.service.toasterErr(`Please enter minimum withdraw amount of ${coinName}`);

      return

    }

    this.service.getApi(`admin/feeUpdate?feeId=${String(coinName)}&amount=${coinAmount ? Number(coinAmount) : 0}`, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.service.toasterSucc(res.responseMessage);
        this.withdrawlFeeList()
      }
    })
  }
}
