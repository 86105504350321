import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.css']
})
export class AddBannerComponent implements OnInit {
  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.addSwatchesFormValidation();

  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl(''),
      'title': new FormControl('', [Validators.required]),

    })
  }





  // addSwatches() {

  //   let url = `admin/addBanner`
  //   let data = {
  //     'title': this.addSwatchesForm.value.title,
  //     'description': this.addSwatchesForm.value.description,
  //     'mediaUrl': this.imageUrl
  //   }
  //   this.commonService.showSpinner();
  //   this.commonService.postApiFunc(url, data, 1).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       this.commonService.toasterSucc(res.responseMessage);
  //       this.router.navigate(['/list-banner'])
  //     } else {
  //       this.commonService.hideSpinner();
  //       this.commonService.toasterErr(res.responseMessage)
  //     }
  //   }, err => {
  //     this.commonService.hideSpinner();
  //     this.commonService.toasterErr(err.error.responseMessage)
  //   })
  // }

  addSwatches() {

    let url = `admin/addBanner`

    let fb = new FormData()
    fb.append('title', this.addSwatchesForm.value.title)
    fb.append('description', this.addSwatchesForm.value.description)
    fb.append('mediaUrl', this.imgg)

    this.commonService.showSpinner();
    this.commonService.postFormDataApi(url, fb, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.toasterSucc(res.responseMessage);
        this.router.navigate(['/list-banner'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage)
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.error.responseMessage)
    })
  }

  imgg: any
  uploadImgg($event): void {
    this.imgg = $event.target.files[0];

  }

  // async uploadImg1($event) {
  //   this.imageUrl = await this.commonService.getBase64($event.target.files[0]);
  // }



  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

}
