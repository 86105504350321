import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { ServiceService } from '../service.service';
declare var $
@Component({
  selector: 'app-withdraw-management',
  templateUrl: './withdraw-management.component.html',
  styleUrls: ['./withdraw-management.component.css']
})
export class WithdrawManagementComponent implements OnInit {

  userID: any;
  action: any;
  staffList: any = [];
  pageNumber: number = 1;
  limit: number = 10;
  staffForm: FormGroup;
  staffLength: any;
  ipAddress: any;
  beDisable: boolean = true;
  bankDetailId: any;
  currTab: any = 'HOT'
  status: any

  constructor(public router: Router, public service: ServiceService, public http: HttpClient) {
    this.staffForm = new FormGroup({
      status: new FormControl(''),
      role: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      search: new FormControl('')
    })
    // this.http.get<{ip:string}>('https://jsonip.com')
    // .subscribe( data => {
    //   this.ipAddress = data.ip
    // })
  }

  ngOnInit() {
    this.getStaffList();
    // this.getVDPrice();
  }

  deleteFunction() {
    $('#deleteModal').modal('hide')
    let data = {
      "ipAddress": this.ipAddress.ip,
      "primaryIdCommonPerRequest": this.userID
    }
    this.service.post('account/admin/user-management/detele-staff', data).subscribe((res) => {
      this.getStaffList();
    })
  }


  // get withdrawal list

  getStaffList() {
    let data = {
      page: this.pageNumber,
      limit: this.limit,
      search: this.staffForm.value.search,
      fromDate: this.staffForm.value.fromDate ? new Date(this.staffForm.value.fromDate) : '',
      toDate: this.staffForm.value.toDate ? new Date(this.staffForm.value.toDate) : ''
    }
    var url = `admin/listAllWithdrawRequest`;
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 1).subscribe(res => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.staffList = res.result.docs;
        this.staffLength = res.result.total

      }
    }, err => {
      this.staffList = []
      this.service.hideSpinner();
      this.service.toasterErr(err.error.responseMessage);
    })
  }

  // Get VD Price

  vd_cmc_price: any
  getVDPrice() {
    var url = `user/getVirtualDenaroValue`;
    this.service.getApi(url, 1).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.vd_cmc_price = res.result.amountInUSD
      }

    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.responseMessage);
    })
  }

  // Pagination

  pagination(page) {
    this.pageNumber = page;
    console.log(page);
    this.getStaffList()
  }

  // Delete / Block Function

  coin_name: any
  amount: any
  coin_id: any
  openModal(action, id, coinName, amount) {
    this.action = action;
    this.coin_id = id;
    this.coin_name = coinName;
    this.amount = amount;
    this.getVDPrice()
    $('#block').modal('show')
  }

  // Approve Reject Function

  performAction() {
    $('#block').modal('hide')
    let data = {}
    let url
    if (this.coin_name == 'VD') {
      let vd_token_price = this.amount * this.vd_cmc_price;
      url = `${this.action == "Approve" ? `admin/acceptWithdrawRequest?amount=${vd_token_price}&_id=${this.coin_id}` : `admin/rejectWithdrawRequest?_id=${this.coin_id}`}`
    } else {
      url = `${this.action == "Approve" ? 'admin/acceptWithdrawRequest' : 'admin/rejectWithdrawRequest'}?_id=${this.coin_id}`
    }
    this.service.showSpinner();
    this.service.putApi(url, data, 1).subscribe((res) => {
      if (res.responseCode == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc(res.responseMessage)
      }
      this.service.hideSpinner();
      this.getStaffList();
    }, err => {
      this.service.hideSpinner();
    })
  }


  // Reset Form

  reset() {
    this.staffForm.reset();
    this.getStaffList();
    this.staffForm.get('status').setValue('All')
  }





  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.staffList.forEach((element, ind) => {

      dataArr.push({
        "ID": ind + 1,
        "Name": element.firstName + '' + element.lastName ? element.lastName : '',
        "Role": element.role ? element.role : 'N/A',
        "Created At": element.createdTime ? element.createdTime.slice(0, 10) : 'N/A',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Staff_list');
  }

}
