import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token: string;
  resetPassToken: any;
  email
  constructor(public activatedRoute: ActivatedRoute, public service: ServiceService, public route: Router, public httpClient: HttpClient) {

  }

  ngOnInit() {

    this.email = localStorage.getItem('resetPassEmail')
    this.resetPassToken = localStorage.getItem('resetPassToken')
    // this.token = window.location.href.split('=')[1];

    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    })
  }

  verifyEmail: any
  resetToken: any
  // Reset Password Functionality
  resetPasswordFunc() {
    // this.resetToken = localStorage.getItem('Auth')
    // this.verifyEmail = localStorage.getItem('email')
    var apireq = {
      // 'email': this.email,
      'password': this.resetPasswordForm.value.password,
      'confirmPassword': this.resetPasswordForm.value.confirmPassword
    }
    var httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        token: this.resetPassToken,
      }),
    };
    // let formDate = new FormData()
    // formDate.append('email', this.verifyEmail)
    // formDate.append('password', this.resetPasswordForm.value.password)
    // formDate.append('confirmPassword', this.resetPasswordForm.value.confirmPassword)
    this.service.showSpinner();

    this.httpClient.post(this.service.baseUrl + 'admin/resetPassword', apireq, httpHeaders).subscribe(res => {

      console.log(res);

      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.service.toasterSucc('Password Set Successfully');
        localStorage.clear()
        this.route.navigate(['/login'])
      } else {
        console.log(res);

        // this.service.toasterErr(res.responseMessage)
      }
    }, err => {
      console.log(err);

      this.service.hideSpinner();
      // this.service.toasterErr(err['error']['responseMessage'])
    })


  }
}

