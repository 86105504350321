import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.css']
})
export class EditBlogComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  contentId: any;
  editData: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.contentId = res.contentId;


    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewBlog()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      'description': new FormControl(''),
      // 'image': new FormControl('')

    })
  }





  addSwatches() {

    // this.router.navigate(['/swatches/list-swatches'])
    let url = `blog/editBlog?_id=${this.contentId}`
    let data = {
      'title': this.addSwatchesForm.value.title,
      'description': this.addSwatchesForm.value.description,
      // 'image': this.imageUrl,
    }
    this.commonService.showSpinner();
    this.commonService.postApiFunc(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.toasterSucc(res.responseMessage);
        this.router.navigate(['/list-blog'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.responseMessage)
      }
    })
  }


  // edit faq english
  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.getApi("blog/viewBlog?_id=" + this.contentId, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.editData = res.result;
        this.commonService.hideSpinner()
        setTimeout(() => {
          this.addSwatchesForm.patchValue({
            'title': this.editData.title,
            'description': this.editData.description
          })
        }, 500);
        // this.imageUrl = this.editData.image
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['responseCode'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }


  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }




}
